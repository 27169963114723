.header-color {
  background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3f51b5, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3f51b5, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

.landing-grid {
  background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3f51b5, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3f51b5, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  text-align: center; 
}

.avatar-img {
  height: 250px;
  padding-top: 5em;
  display: flex;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-text {
  background-color: #121a4a;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-top-left-radius : 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-media-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-media-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}
.thank-you {
  background-color: #121a4a;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-bottom-left-radius : 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
}

.thank-you p {
  color: white;
  font-size: 10px;
  padding: 0em;
}

.thank-you a {
  color: white;
  font-size: 10px;
  padding: 0em;
  text-decoration: none;
}

.thank-you a:hover {
  color: black;
  font-size: 10px;
  padding: 0em;
  color: #3f51b5;
}
